import type {ReactElement} from 'react';
import TextMarker from '@components/Marker/TextMarker';

interface Props {
    name: string;
    url: string;
    className?: string;
    linkClassName?: string;
    ioName?: string;
}

export default function SubMarker(props: Props): ReactElement<Props> | null {
    if (!props.name) {
        return null;
    }

    return <TextMarker {...props} modifier="black" />;
}
