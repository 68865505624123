import type {ReactElement, ReactNode} from 'react';

interface Props {
    className?: string;
    children: ReactNode | string;
    to: string;
    target?: '_blank';
}

function isExternal(link: string): boolean {
    if (!new RegExp('^((http://)|(https://)|(//))').test(link)) {
        // let's assume that this is a relative link
        return false;
    }
    return !new RegExp(window.location.hostname).test(link);
}

function stripHtml(html: string): string {
    const temporalElement = document.createElement('div');
    temporalElement.innerHTML = html;
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return temporalElement.textContent || temporalElement.innerText || '';
}

export default function Link({
    className,
    to,
    children,
    target,
    ...rest
}: Props): ReactElement<Props> {
    return (
        <a
            className={className ?? ''}
            href={to}
            target={target || isExternal(to) ? '_blank' : '_self'}
            rel={target || isExternal(to) ? 'noopener noreferrer nofollow' : undefined}
            {...rest}
        >
            {typeof children === 'string' ? stripHtml(children) : children}
        </a>
    );
}
