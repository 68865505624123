import './accordion';
import './adPopup';
import './makeSystemContainers';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Analytics from '@services/analytics/Analytics';
import OverlayContext from '@context/OverlayContext';
import checkScrollPoints from './scrollAnalyzer';
import imager from '@utils/imager';
import {renderModules} from './moduleList';
import {sendRequiredPaymentEvents} from '@services/payment/paymentEvent';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

smoothScrollPolyfill.polyfill();

declare global {
    interface Window {
        bootstrapParams: {
            imagesLocation: string;
            spriteUrl: string;
            searchEndpoint: string;
            stripePublicKey: string;
            agencyCloudPaymentsPublicKey: string;
            paySelectionPublicKey: string;
            paySelectionSiteId: string;
            paperappHost: string;
            language: string;
        };
        wp: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
            media: Function;
            i18n: {
                /**
                 * @deprecated use i18n service instead
                 */
                __(text: string, domain?: string | undefined): string;
                /**
                 * @deprecated use i18n service instead
                 */
                sprintf(format: string, ...values: (string | number)[]): string;
            };
            data?: {
                dispatch(value: string): {removeEditorPanel(panelName: string): void};
            };
        };
    }
}

Analytics.init();

document.addEventListener('DOMContentLoaded', function () {
    imager.init();
    document.dispatchEvent(new CustomEvent('content-load'));

    checkScrollPoints();
    window.addEventListener('scroll', checkScrollPoints);

    const target = document.querySelector('.banner-top');
    if (target) {
        new MutationObserver(mutations => {
            for (const mutation of mutations) {
                if (mutation.type === 'childList') {
                    document.dispatchEvent(new CustomEvent('top-banner-change'));
                }
            }
        }).observe(target, {
            childList: true,
            subtree: true,
        });
    }

    sendRequiredPaymentEvents();
    document
        .querySelectorAll('.js-menu-item')
        .forEach(item => item.addEventListener('click', toggleMenu));

    document.addEventListener('click', (event: MouseEvent) => {
        for (const item of document.querySelectorAll('.js-header')) {
            if (item.contains(event.target as HTMLElement)) {
                return;
            }
        }
        closeAllMenu();
        void OverlayContext.dispatch(OverlayContext.actions.closeMenu);
    });
});

document.addEventListener('content-load', renderModules);

OverlayContext.registerListener(function (store) {
    if (store.isSearchOpen) {
        document.body.classList.add('freeze');
        closeAllMenu();
    } else {
        document.body.classList.remove('freeze');
    }
});

function toggleMenu(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    if (target.classList.contains('active')) {
        closeAllMenu();
        void OverlayContext.dispatch(OverlayContext.actions.closeMenu);
    } else {
        closeAllMenu();
        target.classList.add('active');
        document.querySelector(`#submenu-${target.dataset.for}`)?.classList.remove('hidden');
        void OverlayContext.dispatch(OverlayContext.actions.openMenu);
    }
}

function closeAllMenu() {
    document.querySelectorAll('.js-menu-item.active').forEach((item: HTMLElement) => {
        item.classList.remove('active');
        document.querySelector(`#submenu-${item.dataset.for}`)?.classList.add('hidden');
    });
}
