import GoogleAnalytics from '@services/analytics/GoogleAnalytics';
import YandexMetrika from '@services/analytics/YandexMetrika';
import FacebookAnalytics from '@services/analytics/FacebookAnalytics';
import type {PurchaseEvent} from '@interfaces/Analytics';

interface YandexParams {
    revenue: number;
    coupon?: string;
}

function init() {
    GoogleAnalytics.init();
    YandexMetrika.init();
}

function triggerEvent(eventName: string, eventType: string, params = {}) {
    GoogleAnalytics.triggerEvent(eventName, eventType, params);
    YandexMetrika.triggerEvent(eventName, params);
}

function triggerEventWithPayment(event: PurchaseEvent) {
    GoogleAnalytics.triggerEventWithPayment(event);
    YandexMetrika.triggerEventWithPayment(event);
    FacebookAnalytics.triggerEventWithPayment(event);

    const yandexParams: YandexParams = {revenue: event.price};
    if (event.discount) {
        yandexParams.coupon = event.discount;
    }
    YandexMetrika.sendEcommerceAction(`${event.eventName} ${event.eventLabel}`, yandexParams);
}

function triggerPageView() {
    GoogleAnalytics.triggerPageView();
    YandexMetrika.triggerPageView();
    FacebookAnalytics.triggerPageView();
}

export default {
    init,
    triggerEvent,
    triggerPageView,
    triggerEventWithPayment,
};
