import type {ReactElement} from 'react';
import TextMarker from '@components/Marker/TextMarker';

interface Props {
    name: string;
    slug?: string;
    url?: string;
    type?: string;
    className?: string;
    linkClassName?: string;
    ioName?: string;
}

export default function MainMarker(props: Props): ReactElement<Props> | null {
    if (!props.name) {
        return null;
    }

    function getUrl(): string {
        let result = props.url ?? '';

        if (props.slug) {
            result = `/format/${props.slug}`;
        }

        return result;
    }

    return <TextMarker {...props} url={getUrl()} />;
}
