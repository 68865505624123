import type {FormEvent, HTMLProps, ReactElement, RefObject} from 'react';
import classNames from 'classnames';
import Icon from '@components/Icon';

interface Props extends HTMLProps<HTMLButtonElement> {
    iconName: string;
    className?: string;
    type: 'button' | 'reset' | 'submit';
    buttonRef?: RefObject<HTMLButtonElement>;
    onClick?(event: FormEvent): void;
}

export default function ButtonIcon(props: Props): ReactElement {
    const {className, iconName, buttonRef, ...rest} = props;
    const classes = classNames({
        'button-icon': true,
        [className!]: className,
    });

    return (
        // eslint-disable-next-line react/button-has-type
        <button className={classes} {...rest} ref={buttonRef}>
            <Icon name={iconName} />
        </button>
    );
}
