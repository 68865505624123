import type {MouseEvent} from 'react';

interface Props {
    className?: string;
    name: string;
    onClick?(event: MouseEvent): void;
}

export default function Icon(props: Props) {
    return (
        <svg onClick={props.onClick} className={`svgicon ${props.className ?? ''}`}>
            <use href={`${window.bootstrapParams.spriteUrl}#${props.name}`} />
        </svg>
    );
}
