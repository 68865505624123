import RequestClass from '@api/request/RequestClass';
import {API_NAMESPACE} from '@constants/general';
import type GetYandexTokenResponse from '@interfaces/Analytics';
import {METHOD_POST} from '@constants/http';
import {getIdentifier} from '@utils/identifier';

export async function getYandexToken(): Promise<GetYandexTokenResponse> {
    return (await new RequestClass(
        `${API_NAMESPACE}/yandex/token`,
    ).send()) as Promise<GetYandexTokenResponse>;
}

export async function sendEventLog(name: string): Promise<object> {
    return (await new RequestClass(`${API_NAMESPACE}/gtag/event`)
        .setParams({name, url: window.location.href, identifier: getIdentifier()})
        .setMethod(METHOD_POST)
        .send()) as Promise<object>;
}
