function changeCls(el: HTMLImageElement) {
    if (el.parentElement) {
        el.parentElement.className += ' r-img_shown';
    }
}

function preload(el: HTMLImageElement) {
    if (el.complete) {
        changeCls(el);
    } else {
        el.onload = changeCls.bind(null, el);
    }
}

export default {
    init() {
        const list = Array.from(document.querySelectorAll('img')).filter(el =>
            Object.hasOwn(el.dataset, 'preload'),
        );
        for (const item of list) {
            preload(item);
        }
    },
};
