import type {EcommerceData, OrderStatus, OrderStatusUpdateParams} from '@interfaces/YandexMetrika';
import RequestClass from '@api/request/RequestClass';
import {METHOD_POST} from '@constants/http';
import {format} from 'date-fns';
import {getYandexToken} from '@api/analytics';
import type {PurchaseEvent} from '@interfaces/Analytics';

declare global {
    type YMThirdParam = string | ((clientId: string) => void);

    interface Window {
        ym(id: number, eventType: string, thirdParam: YMThirdParam, params?: Params): Promise<void>;

        dataLayer?: EcommerceData[];
    }
}

type Params = Record<string, unknown>;

interface PurchaseParams {
    coupon?: string;
    goal_id?: number;
    revenue?: number;
}

const YM_ID = 50712292;

function init() {
    window.dataLayer = window.dataLayer ?? [];
}

function triggerEvent(eventName: string, params = {}) {
    sendEvent(eventName, params);
}

function triggerEventWithPayment(event: PurchaseEvent) {
    sendEvent(event.eventName, {
        order_price: event.price,
        currency: event.currency,
    });
}

function sendEcommerceAction(id: string, params: PurchaseParams) {
    if (!window.dataLayer) {
        init();
    }

    window.dataLayer?.push({
        ecommerce: {
            currencyCode: 'RUB',
            purchase: {
                actionField: {
                    id,
                    ...params,
                },
                products: [
                    {
                        id,
                    },
                ],
            },
        },
    });
}

function sendOrderStatus(
    orderId: string,
    orderStatus: OrderStatus,
    revenue: number,
    products: Record<string, number>,
) {
    void getClientId().then(clientId => {
        const params: OrderStatusUpdateParams = {
            orders: [
                {
                    id: orderId,
                    client_uniq_id: clientId,
                    client_type: 'CONTACT',
                    create_date_time: format(new Date(), 'y-MM-dd HH:mm:ss'),
                    finish_date_time: format(new Date(), 'y-MM-dd HH:mm:ss'),
                    ...{revenue},
                    cost: 0,
                    order_status: orderStatus,
                    ...{products},
                },
            ],
        };

        void getYandexToken().then(async response =>
            new RequestClass(
                `https://api-metrika.yandex.net/cdp/api/v1/counter/${YM_ID}/data/orders?merge_mode=UPDATE`,
                METHOD_POST,
            )
                .setAuthHeader(response.data)
                .setParams(params)
                .send(),
        );
    });
}

async function getClientId(): Promise<string> {
    let result = '';
    if (typeof window.ym !== 'undefined') {
        await window.ym(YM_ID, 'getClientID', (clientId: string) => {
            result = clientId;
        });
    }

    return result;
}

function sendEvent(eventName: string, params?: Params) {
    if (typeof window.ym !== 'undefined') {
        void window.ym(YM_ID, 'reachGoal', eventName, params);
    }
}

function triggerPageView() {
    if (typeof window.ym !== 'undefined') {
        void window.ym(YM_ID, 'hit', location.pathname + location.search);
    }
}

export default {
    init,
    triggerEvent,
    triggerEventWithPayment,
    triggerPageView,
    sendEcommerceAction,
    sendOrderStatus,
};
