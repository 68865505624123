import classNames from 'classnames';
import ButtonIcon from '@components/ButtonIcon';
import type {MouseEvent, ReactElement, RefObject} from 'react';

interface Props {
    buttonRef: RefObject<HTMLButtonElement>;
    isSearchOpen?: boolean;
    onClick(event: MouseEvent): void;
}

export default function SearchButton({
    isSearchOpen,
    onClick,
    buttonRef,
}: Props): ReactElement<Props> {
    const classes = classNames({
        'search-button': !isSearchOpen,
        'search-button--active': isSearchOpen,
    });

    return (
        <ButtonIcon
            type="button"
            iconName="search"
            className={classes}
            onClick={onClick}
            buttonRef={buttonRef}
        />
    );
}
