import type {PurchaseEvent} from '@interfaces/Analytics';

declare global {
    interface Window {
        // eslint-disable-next-line no-undef
        fbq?: facebook.Pixel.Event;
    }
}

function triggerEvent(eventType: string, params: object) {
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', eventType, {
            ...params,
        });
    }
}

function triggerEventWithPayment(event: PurchaseEvent) {
    triggerEvent('Purchase', {
        content_type: 'product',
        content_ids: [event.eventLabel],
        content_name: event.eventName,
        value: event.price,
        currency: event.currency,
    });
}

function triggerEventAddToCart(id: string, name = '') {
    triggerEvent('AddToCart', {
        content_type: 'product',
        content_ids: [id],
        content_name: name,
    });
}

function triggerPageView() {
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'PageView');
    }
}

export default {
    triggerEvent,
    triggerEventWithPayment,
    triggerEventAddToCart,
    triggerPageView,
};
