import type {MouseEvent, ReactElement} from 'react';
import PostsLoading from '@components/PostsLoading';
import ButtonOutline from '@components/ButtonOutline';
import I18n from '@components/I18n';

interface Props {
    loading: boolean;
    onClick(event: MouseEvent): void;
}

export default function MoreButton(props: Props): ReactElement {
    if (props.loading) {
        return <PostsLoading />;
    }
    return (
        <ButtonOutline type="button" className="more-button" onClick={props.onClick}>
            <I18n code="MoreButtonLabel" />
        </ButtonOutline>
    );
}
