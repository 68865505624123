/* eslint-disable @typescript-eslint/no-deprecated */
export function getCurrentLanguage(): string {
    return window.bootstrapParams.language;
}

export function localize(code: string): string {
    return window.wp.i18n.__(code, 'paperpress');
}

export function sprintf(template: string, ...values: string[] | number[]): string {
    return window.wp.i18n.sprintf(template, ...values);
}
