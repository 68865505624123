import UserBlockFallback from '@components/Profile/UserBlock/UserBlockFallback';
import Search from '@components/Search';
import type {UserBlockProps} from '@interfaces/Profile';
import {lazy} from 'react';
import renderComponent from './renderComponent';

const Feedback = lazy(async () => import(/* webpackChunkName: "feedback" */ '@components/Feedback'));
const Likes = lazy(async () => import(/* webpackChunkName: "likes" */ '@components/Likes'));
const Gallery = lazy(async () => import(/* webpackChunkName: "gallery" */ '@components/Gallery'));
const Mosaic = lazy(async () => import(/* webpackChunkName: "mosaic" */ '@components/MosaicLazy'));
const PhotoSlider = lazy(async () => import(/* webpackChunkName: "photo-slider" */ '@components/PhotoSlider'));
const Stream = lazy(async () => import(/* webpackChunkName: "stream" */ '@components/Stream'));
const SubscriptionForm = lazy(async () => import(/* webpackChunkName: "subscription-form" */ '@components/SubscriptionForm'));
const UserBlock = lazy(async () => import(/* webpackChunkName: "user-block" */ '@components/Profile/UserBlock'));
const PostStrip = lazy(async () => import(/* webpackChunkName: "post-strip" */ '@components/Strips/PostStrip'));
const NewsletterCardsScroller = lazy(async () => import(/* webpackChunkName: "newsletter-cards-scroller" */ '@components/NewsletterScroller/NewsletterCardsScroller'));
const RegisterButton = lazy(async () => import(/* webpack-chunk-name: "register-button" */ '@components/RegisterButton'));
const SubscriberPreview = lazy(async () => import(/* webpackChunkName: "subscriber-preview" */ '@components/Preview/SubscriberPreview'));
const ClubMemberPreview = lazy(async () => import(/* webpackChunkName: "club-member-preview" */ '@components/Preview/ClubMemberPreview'));
const Test = lazy(async () => import(/* webpackChunkName: "test" */ '@components/Test'));
const Reaction = lazy(async () => import(/* webpackChunkName: "reaction" */ '@components/Reaction'));
const ProfileTabsBlock = lazy(async () => import(/* webpackChunkName: "profile-tabs-block" */ '@components/Profile/TabsBlock'));
const SubscriptionList = lazy(async () => import(/* webpackChunkName: "subscription-list" */ '@components/Profile/TabsBlock/SubscriptionsScreen/SubscriptionList'));
const ClubForm = lazy(async () => import(/* webpackChunkName: "club-form" */ '@components/ClubForm'));
const CustomCloudPaymentsForm = lazy(async () => import(/* webpackChunkName: "custom-cloudpayments-form" */ '@components/CustomForm/CloudPayments'));
const CustomStripeForm = lazy(async () => import(/* webpackChunkName: "custom-stripe-form" */ '@components/CustomForm/Stripe'));
const TypoButton = lazy(async () => import(/* webpackChunkName: "typo-button" */ '@components/TypoButton'));

export function renderModules() {
    renderComponent('.js-element-feedback', Feedback, 'feedback-loading');
    renderComponent('.js-element-likes', Likes);
    renderComponent('.js-element-subscription-form', SubscriptionForm); // needs loading
    renderComponent('.js-element-stream', Stream);
    renderComponent('.js-element-mosaic', Mosaic);
    renderComponent('.js-element-post-strip', PostStrip);
    renderComponent('.js-element-gallery', Gallery); // needs loading
    renderComponent('.js-element-slider', PhotoSlider); // needs loading
    renderComponent<UserBlockProps>('.js-element-user-block', UserBlock, UserBlockFallback);
    renderComponent('.js-element-newsletter-cards-scroller', NewsletterCardsScroller); // needs loading
    renderComponent('.js-element-subscriber-preview', SubscriberPreview);
    renderComponent('.js-element-club-member-preview', ClubMemberPreview);
    renderComponent('.js-element-register-button', RegisterButton);
    renderComponent('.js-element-search-button', Search);
    renderComponent('.js-element-test', Test); //needs loading
    renderComponent('.js-element-reaction', Reaction); //needs loading
    renderComponent('.js-element-profile-tabs-block', ProfileTabsBlock);
    renderComponent('.js-element-subscription-list', SubscriptionList);
    renderComponent('.js-element-club-form', ClubForm);
    renderComponent('.js-element-custom-form-cloudpayments', CustomCloudPaymentsForm);
    renderComponent('.js-element-custom-form-stripe', CustomStripeForm);
    renderComponent('.js-element-typo-button', TypoButton);
}
