import {localize} from '@services/i18n';
import type {ReactElement} from 'react';

interface Props {
    code: string;
}

export default function I18n({code}: Props): ReactElement {
    return <>{localize(code)}</>;
}
