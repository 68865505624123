import type {HTMLProps, MouseEvent, ReactNode} from 'react';

interface Props extends HTMLProps<HTMLButtonElement> {
    colorModifier?: string;
    children: ReactNode;
    type: 'button' | 'reset' | 'submit';
    onClick?(event: MouseEvent): void;
}

export default function TextButton({colorModifier, className, children, ...rest}: Props) {
    const baseClass = colorModifier ? `button-text--${colorModifier}` : 'button-text';
    return (
        // eslint-disable-next-line react/button-has-type
        <button className={`${baseClass} ${className ?? ''}`} {...rest}>
            {children}
        </button>
    );
}
