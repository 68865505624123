import type ValueList from '@interfaces/ValueList';

type StateChangeListener<T extends object> = (store: T, action: string) => void;

export default abstract class Context<T extends object> {
    protected state: T;
    protected listeners: StateChangeListener<T>[] = [];

    protected constructor(initialState: T) {
        this.state = initialState;
    }

    public registerListener(listener: StateChangeListener<T>) {
        this.listeners.push(listener);
    }

    public deregisterListener(listener: StateChangeListener<T>) {
        this.listeners = this.listeners.filter(item => item !== listener);
    }

    protected abstract reduce(action: string, data: ValueList): T | Promise<T>;

    public async dispatch(action: string, data = {}): Promise<T> {
        this.state = await this.reduce(action, data);
        this.listeners.forEach(item => item({...this.state}, action));
        return this.state;
    }

    public get currentState(): T {
        return {...this.state};
    }
}
