import defaultConfig from '@config/googleAnalyticsEvents.json';
import type {PurchaseEvent} from '@interfaces/Analytics';
import type ValueList from '@interfaces/ValueList';
import {sendEventLog} from '@api/analytics';

declare global {
    interface Window {
        // eslint-disable-next-line no-undef
        ga?: UniversalAnalytics.ga;
        // eslint-disable-next-line no-undef
        gtag?: Gtag.Gtag;
    }
}

export type AnalyticsConfig = Record<string, AnalyticsEvent[]>;

export interface AnalyticsEvent {
    description: string;
    selector: string;
    type: string;
    paramSource?: ParamSource;
}

export type ParamSource = string | ParamSourceAdvanced;

export interface ParamSourceAdvanced {
    element?: string;
    attribute?: string;
}

function init(config: AnalyticsConfig = defaultConfig) {
    const eventList: AnalyticsEvent[] = [].concat(...(Object.values(config) as []));
    for (const analyticsEvent of eventList) {
        document.addEventListener(analyticsEvent.type, event => {
            if (!event.target) {
                console.error('no target present for a selector-based analytics event');
            }

            const currentTarget = event.target as Element;

            if (currentTarget.matches(analyticsEvent.selector)) {
                triggerEvent(analyticsEvent.description, analyticsEvent.type, {
                    param: getParam(analyticsEvent, currentTarget),
                });
            } else {
                const parentTarget = currentTarget.closest(analyticsEvent.selector);

                if (parentTarget) {
                    triggerEvent(analyticsEvent.description, analyticsEvent.type, {
                        param: getParam(analyticsEvent, parentTarget),
                    });
                }
            }
        });
    }
}

function triggerEvent(eventName: string, eventType: string, params?: ValueList) {
    if (typeof window.ga !== 'undefined') {
        window.ga('send', 'event', eventName, eventType, ...objectToArray(params));
    }
    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', eventName, {
            event_type: eventType,
            ...params,
        });
    }
}

function triggerEventWithPayment(event: PurchaseEvent) {
    if (typeof window.ga !== 'undefined') {
        triggerEvent(event.eventName, event.eventType, {
            label: event.eventLabel,
            price: event.price,
        });
    }
    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'purchase', {
            currency: event.currency,
            value: event.price,
            coupon: event.discount,
            items: [
                {
                    item_id: event.eventName,
                    item_name: event.eventName,
                },
            ],
        });
        sendEventLog(`purchase event from paperpaper GoogleAnalytics.ts: ${event.eventName}`).catch(
            () => {
                /* do nothing */
            },
        );
    }
}

function triggerPageView() {
    if (typeof window.ga !== 'undefined') {
        window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview');
    }
    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: location.pathname + location.search,
        });
    }
}

function objectToArray(params?: ValueList): unknown[] {
    if (!params) {
        return [];
    }
    return Object.values(params);
}

function getParam(analyticsEvent: AnalyticsEvent, targetElement: Element): string | null {
    if (!analyticsEvent.paramSource) {
        return null;
    }

    if (analyticsEvent.paramSource === '__self__') {
        return (targetElement.textContent ?? '').trim();
    }

    const paramSourceAdvanced = analyticsEvent.paramSource as ParamSourceAdvanced;

    if (paramSourceAdvanced.attribute) {
        return (targetElement.getAttribute(paramSourceAdvanced.attribute) ?? '').trim();
    }

    if (paramSourceAdvanced.element) {
        const element = targetElement.querySelector(paramSourceAdvanced.element);
        return element ? (element.textContent ?? '').trim() : null;
    }

    return null;
}

export default {
    init,
    triggerEvent,
    triggerPageView,
    triggerEventWithPayment,
};
